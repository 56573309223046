
import Rating from '@/components/Rating.vue';

export default {
  name: 'treeItem',
  components: { Rating },
  props: {
    item: Object,
    scoreChange: Function,
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    isFolder() {
      return this.item.children && this.item.children.length;
    },
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },
  },
};
