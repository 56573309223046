
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import TreeItem from '@/components/TreeItem.vue';
import UserInfo from '@/components/UserInfo.vue';
import {
  getCategories,
  getUserData,
  postUserData,
} from '../services/DataService';
import auth from '@/stores/auth';

export default {
  name: 'Update',
  components: {
    Loading,
    UserInfo,
    TreeItem,
  },
  created() {
    window.addEventListener('beforeunload', this.checkLeave);
  },
  async mounted() {
    this.isLoading = true;
    if (!document.getElementById('skills-matrix-entry-container')) {
      return;
    }
    if (auth.email) {
      this.userEmail = auth.email;
      this.userFirstName = auth.firstName;
      this.userLastName = auth.lastName;
      this.getCategoriesAndUserData().then(() => {
        this.isLoading = false;
      });
    } else {
      this.userLoadError = 'Could not load data for current user';
      this.getCategories().then(() => {
        this.isLoading = false;
      });
    }
  },
  computed: {
    dev() {
      return window.location.hostname === 'localhost';
    },
    isUser() {
      return this.userEmail === auth.email;
    },
  },
  data() {
    return {
      isLoading: false,
      scoreModel: {
        score: '',
        name: 'Rating:',
      },
      treeData: {
        children: [],
      },
      userData: {
        email: '',
        scores: [],
      },
      userEmail: '',
      userFirstName: '',
      userLastName: '',
      userLoadError: null,
      dataChanged: false,
    };
  },
  methods: {
    async getCategories() {
      let categoriesData;
      try {
        categoriesData = await getCategories();
      } catch (e) {
        console.error(e);
      }
      this.treeData = categoriesData;
    },
    async getCategoriesAndUserData() {
      let userDataRes;
      let userData;
      let categoriesData;
      try {
        categoriesData = await getCategories();
      } catch (e) {
        console.error(e);
        return;
      }

      try {
        userDataRes = await getUserData(this.userEmail);
        if (userDataRes.status === 404) {
          this.userData = {
            email: this.userEmail,
            scores: [],
          };
          this.addScoreDataToCategories(categoriesData, []);
          this.treeData = categoriesData;
          return;
        }
        userData = await userDataRes.json();
      } catch (e) {
        this.userLoadError = 'Error getting data for current user';
        console.error(e);
        return;
      }

      this.addScoreDataToCategories(categoriesData, userData.scores);
      this.treeData = categoriesData;
      this.userData = userData;
    },
    scoreChangeHandler() {
      this.dataChanged = true;
    },
    addScoreDataToCategories(categories, scores) {
      scores.forEach((score) => {
        const category = this.findCategory(categories, score.category_id);
        if (category) {
          category.score = score.score;
        }
      });
    },
    findCategory(tree, id) {
      let found = null;

      function recurse(node) {
        if (!node.children) {
          if (node.id === id) {
            found = node;
          }
        } else {
          node.children.forEach(recurse);
        }
      }

      recurse(tree);
      return found;
    },
    findCategoryLeafNodes(categoryTree) {
      const categoryLeafNodes = [];

      function recurse(tree) {
        if (tree.children && tree.children.length > 0) {
          tree.children.forEach((child) => {
            recurse(child);
          });
        } else {
          categoryLeafNodes.push(tree);
        }
      }

      recurse(categoryTree);
      return categoryLeafNodes;
    },
    saveData() {
      if (
        !this.isUser &&
        !confirm(`Please confirm to update skills for ${this.userEmail}!`)
      ) {
        return;
      }

      this.onSaveData();
    },
    onSaveData() {
      this.isLoading = true;
      let categoryLeafNodes = this.findCategoryLeafNodes(this.treeData);
      categoryLeafNodes = categoryLeafNodes.filter((node) => node.score > -1);
      const scores = categoryLeafNodes.map((leafNode) => ({
        category_id: leafNode.id,
        score: leafNode.score,
      }));

      if (!this.dev) {
        postUserData(this.userData.email, {
          scores,
          firstName: this.userFirstName,
          lastName: this.userLastName,
        })
          .then((res) => {
            console.log(res);
            this.dataChanged = false;
            this.isLoading = false;

            this.$toasted.show('Save successful', {
              theme: 'bubble',
              singleton: true,
              type: 'success',
              position: 'bottom-center',
              duration: 2000,
            });
          })
          .catch((e) => {
            this.isLoading = false;
            this.$toasted.show('Error saving', {
              theme: 'bubble',
              singleton: true,
              type: 'error',
              position: 'bottom-center',
              duration: 2000,
            });
            console.warn(e);
          });
      } else {
        console.log(scores);
        setTimeout(() => {
          this.dataChanged = false;
          this.isLoading = false;
          this.$toasted.show('Saved', {
            theme: 'bubble',
            singleton: true,
            type: 'success',
            position: 'bottom-center',
            duration: 2000,
          });
        }, 2500);
      }
    },
    checkLeave(e) {
      if (this.dataChanged) {
        const confirmationMessage =
          'It looks like you have been editing something. ' +
          'If you leave before saving, your changes will be lost.';

        (e || window.event).returnValue = confirmationMessage; // Gecko + IE
        return confirmationMessage;
      }

      return undefined;
    },
  },
};
