
import RatingModel from '@/models/RatingModel';

export default {
  name: 'Rating',
  props: {
    model: RatingModel,
    scoreChange: Function,
  },
  computed: {
    scoreOptions() {
      return [
        { value: 0, text: 'None' },
        { value: 1, text: 'Some' },
        { value: 2, text: 'Reasonable' },
        { value: 3, text: 'Heaps' },
      ];
    },
  },
};
